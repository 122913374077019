import React from 'react'
import './Join.css'
import { useRef } from 'react'
import emailjs from '@emailjs/browser'
const Join = () => {
    const form =useRef()
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_dg8s8qo', 'template_lno01gl', form.current, 'CEfRkc0MirX603BHm')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };
  return (
    <div className='join' id='join-us'>
            <div className='Left-j'>
                <hr/>
                 <div>
                    <span className='stroke-text'>READY TO</span>
                    <span>LEVEL UP</span>
                 </div>
                 <div>
                    <span>YOUR BODY</span>
                    <span className='stroke-text'>WITH US?</span>
                 </div>
            </div>
            <div className='right-j'>
                <form ref={form} className='email-conatainer' onSubmit={sendEmail}>
                    <input type='email' placeholder='Enter your email address' name='user_email'></input>
                    <button className='btn btn-j'>Join now</button>
                </form>
            </div>
    </div>
  )
}

export default Join