import React from 'react'
import './Hero.css'
import Header from '../Header/Header'
import Heart from '../../assets/heart.png'
import hero_image from '../../assets/hero_image.png'
import hero_image_back from '../../assets/hero_image_back.png'
import calories from '../../assets/calories.png'
import {motion} from 'framer-motion'
import NumberCounter from 'number-counter'
const Hero = () => {
    const transtion={type:'spring',duration:3}
    const mobile=window.innerWidth<=786 ? true :false;
    return (
        <div className='hero' id='home'>
            <div className='blur hero-blur'></div>
            <div className='left-h'>
                <Header />
                {/* the best add */}
                <div className='the-best-ad'>
                    <motion.div
                    initial={{left:mobile? "178px":'238px'}}
                    whileInView={{left:'8px'}}
                    transition={{...transtion,type:'tween'}}
                    >

                    </motion.div>
                    <span>the best fitness club in the town</span>
                </div>
                {/* Hero Heading */}
                <div className='hero-text'>
                    <div>
                        <span className='stroke-text'>Shape </span>
                        <span>Your</span>
                    </div>
                    <div>
                        <span>Ideal Body</span>
                    </div>
                    <div >
                        <span>
                            In Here We Will Help You To Shape And Build Your Idea Body And Live Up Your Life To Fullset
                        </span>
                    </div>
                </div>
                {/* figure */}
                <div className='figures'>
                    <div>
                        <span>
                            <NumberCounter end={140} start={100} delay='4' preFix="+"/>
                        </span>
                        <span>expert coachs</span>
                    </div>
                    <div>
                        <span>
                        <NumberCounter end={978} start={800} delay='4' preFix="+"/>
                        </span>
                        <span>member joined</span>
                    </div>
                    <div>
                        <span>
                        <NumberCounter end={50} start={0} delay='4' preFix="+"/>
                        </span>
                        <span>fitness programs</span>
                    </div>
                </div>
                {/* Button */}
                <div className='hero-buttons'>
                <button className='btn'>Get Started</button>
                <button className='btn'>Learn More</button>
                </div>
            </div>
            <div className='right-h'>
                <button className='btn'>
                    Join Now
                </button>
                <motion.div
                initial={{right:'-1rem'}}
                whileInView={{right:'4rem'}}
                transition={transtion}
                 className='heart-rate'>
                    <img src={Heart} alt=''/>
                    <span>Heart Rate</span>
                    <span>116 bpm</span>
                </motion.div>
                {/* Hero Images  */}
                <img src={hero_image} alt='' className='hero-image'/>
                <motion.img 
                initial={{right:'11rem'}}
                whileInView={{right:'20rem'}}
                transition={transtion}
                src={hero_image_back} alt='' className='hero-image-back'/>
                {/* Calories */}
                <motion.div
                initial={{right:'37rem'}}
                whileInView={{right:'28rem'}}
                transition={transtion}
                 className='calories'>
                    <img src={calories} alt=""/>
                    <div>
                    <span>Calories Burned</span>
                    <span>220 kcal</span>
                    </div>
                    
                </motion.div>
            </div>
        </div>
    )
}

export default Hero